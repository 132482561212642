<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      height="80"
      hide-on-scroll
    >
      <v-app-bar-title>
      <img src="./assets/logo_mmreality.svg" height="60" contain />
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="float-right d-lg-flex d-md-flex d-xl-flex d-sm-flex d-none">
        <v-btn color="accent" href="https://www.prodejzanejvic.cz/#contact_form" target="_blank">chci prodat v aukci</v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <v-container>
      <div class="d-sm-none text-center mt-5">
        <v-btn color="accent" href="https://www.prodejzanejvic.cz/#contact_form" target="_blank">chci prodat v aukci</v-btn>
      </div>
      <template v-if="stream">
        <div class="mt-5 d-flex">
          <h2> {{ title }}</h2>
          <v-spacer></v-spacer>
          <v-btn @click="question_form = true">Máte dotaz?</v-btn>
        </div>
      <v-responsive  id="video-container" :aspect-ratio="16/9" class="d-flex align-center text-center ma-auto align-stretch" max-width="1200">
        <you-tube :video-id="videoId" :player-vars="{autoplay: 1}" class="main-video" :resize="true" :fit-parent="true" max-width="1200" ></you-tube>
      </v-responsive>
<v-row>
</v-row>
      <v-row class="justify-center">
      <v-container style="max-width: 1200px" class="mt-2">
        <v-dialog v-model="question_form" width="40rem">
        <v-card>
          <v-card-title>Zadejte prosím své jméno a dotaz</v-card-title>
          <v-card-text>
            <v-alert :value="question_success" type="success" dismissible>Dotaz byl úspěšně odeslán</v-alert>
            <v-form v-model="valid">
          <div class="d-inline">
          <v-text-field v-model="name" label="Vaše jméno" prepend-icon="mdi-account" required :rules="[v => !!v && v.length > 3 || 'Zadejte prosím své jméno']" ></v-text-field>
          </div>
          <v-textarea v-model="question" label="Váš dotaz" prepend-icon="mdi-comment" rows="1" required :rules="[v => !!v && v.length > 10 || 'Zadejte prosím dotaz']"></v-textarea>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions >
            <v-spacer></v-spacer>
            <v-btn @click.stop="clearForm" text>Zavřít</v-btn>
            <vue-recaptcha sitekey="6Ld1EcMaAAAAAE71ckDdjeqdXswMWq0FXo217reM" @verify="onVerify" size="invisible">
              <v-btn class="ml-5" ref="form_submit" color="primary" :disabled="!valid">Odeslat dotaz</v-btn>
            </vue-recaptcha>
          </v-card-actions>
        </v-card>
        </v-dialog>
      </v-container>
      </v-row>
      </template>
      <div v-else class="justify-center">
        <v-alert type="warning">Žádná aukce není naplánována</v-alert>
      </div>
      </v-container>
      <v-footer color="transparent" padless>
        <v-img  width="100%" src="./assets/footer.svg"></v-img>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import YouTube from "./components/YouTube";

export default {
  name: 'App',
  components: {
    VueRecaptcha,
    YouTube
  },

  head: {
    script: [
      {
        src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit',
        async: true
      }
    ]
  },
  data: () => ({
    //
    videoId: 'IwzjgvedxWk',
    title : '',
    question: '',
    question_success: false,
    question_form:false,
    name: '',
    stream: null,
    valid: false
  }),
  mounted() {
    axios.get(process.env.VUE_APP_BACKEND_URL+ '/streams-oncoming').then( r => {
      const data  = r.data
      this.stream = data
      this.videoId = data.youtubeId
      this.title = data.description
    })

    axios.get('https://api.mm-aukce.cz/api/v1/public/server/time')
  },
  methods:
      {
        formSubmit() {
          /*

           */
        },
        clearForm() {
          this.question_form = false
          this.question = ''
        },
        onVerify: function (response) {
          if (this.valid) {
            axios.post(process.env.VUE_APP_BACKEND_URL + '/questions', {
              author: this.name,
              message: this.question,
              stream: this.stream.id,
              recaptchaResponse: response
            }).then(r => {
              if (r.status === 200) {
                this.question = ''
                this.question_success = true
                setTimeout(() => {
                  this.question_success = false;
                  this.question_form = false
                }, 2000)
              }
            }).catch( ()=>{

            })
          }
        },

      },
};
</script>
<style scoped lang="css">
.main-video {
  height: 100%;
}

h2 {
  color:#1b5e20;
}
</style>
